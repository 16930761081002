<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="pt-40">
    <div class="rounded-md bg-red-50 p-4">
      <div class="flex">
        <div class="flex-shrink-0">
          <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div class="ml-3">
          <h3 class="text-sm font-medium text-red-800">
            Failed
          </h3>
          <div class="mt-2 text-sm text-red-700">
            <ul role="list" class="list-disc pl-5 space-y-1">
              <li>
                {{$store.state.auth.last_course_message}}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="pt-4 space-y-4 text-red-800" v-if="$store.state.auth.can_again">
        <div class="flex items-center cursor-pointer hover:text-red-900" @click="$router.push({name: 'Start', params: {id: $route.params.id}}); this.$store.commit('auth/setCanAgain', false)">
          <p>Listen course again </p>
          <academic-cap-icon class="w-6 h-6"/>
        </div>

        <div class="flex items-center cursor-pointer hover:text-red-900" @click="$store.commit('auth/setListen', false); $router.push({name: 'Start', params: {id: $route.params.id}}); this.$store.commit('auth/setCanAgain', false)">
          <p>Retake test</p>
          <refresh-icon class="w-6 h-6"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { XCircleIcon, RefreshIcon, AcademicCapIcon } from '@heroicons/vue/solid'
import {mapActions} from 'vuex'

export default {
  components: {
    XCircleIcon,
    RefreshIcon,
    AcademicCapIcon
  },
  methods: {
    ...mapActions('auth', ['fetchUser'])
  },
  mounted() {
    this.fetchUser()
  }
}
</script>